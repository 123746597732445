document.addEventListener("page:load", (event) => {
  const modal = document.getElementById('renewalModal');

  if (modal && !sessionStorage.getItem("renewal-reminder")) {
    const renewalModal = new Bootstrap.Modal(modal, {});
    renewalModal.show();

    modal.addEventListener('hidden.bs.modal', event => {
      sessionStorage.setItem("renewal-reminder", "true");
    });

    modal.querySelector(".renewal-button").addEventListener("click", e => {
      sessionStorage.setItem("renewal-reminder", "true");
    });
  }
});