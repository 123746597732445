document.addEventListener('page:load', () => {
  document.querySelectorAll('[data-ga]').forEach((element) => {
    element.addEventListener('click', () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'click_cta',
        'id': element.dataset.gaId || element.id,
        'text': element.dataset.gaValue,
        'value': element.dataset.ga,
        'url': window.location.href,
      });
    });
  });

  const businessSearch = document.getElementById("business_search");

  if (businessSearch) {
    businessSearch.addEventListener('turbo:before-fetch-response', (e) => {
      const urlParams = new URLSearchParams(e.detail.fetchResponse.response.url.split("?")[1]);

      const gtagData = {};

      if (urlParams.get("letter")) {
        gtagData["letter"] = urlParams.get("letter");
      }

      if (urlParams.get("search_by[certification]")) {
        gtagData["certification"] = urlParams.get("search_by[certification]");
      }

      if (urlParams.get("search_by[business_product_category_id]")) {
        gtagData["business_product_category_id"] = urlParams.get("search_by[business_product_category_id]");
      }

      if (urlParams.get("search")) {
        gtagData["search"] = urlParams.get("search");
      }

      if (Object.keys(gtagData).length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'business_search',
          ...gtagData
        });
      }
    });
  }

  const productParams = new URLSearchParams(window.location.search);

  if (productParams.has("submitted")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'product_submit',
      'id': productParams.get("product_id"),
      'type': productParams.get("type"),
      'certification': productParams.get("certification")
    });
  }
});

