// document.addEventListener("turbo:before-cache", function () {
//   // This is required if we re-enable turbo cache
//   document.querySelectorAll('[data-aos]').forEach((element) => {
//     element.remove();
//   })
// });

document.addEventListener('page:load', function () {
  if (window.innerWidth < 768) {
    [].slice.call(document.querySelectorAll('[data-bss-disabled-mobile]')).forEach(function (elem) {
      elem.classList.remove('animated');
      elem.removeAttribute('data-bss-hover-animate');
      elem.removeAttribute('data-aos');
    });
  }

  document.querySelectorAll('.aos-init.aos-animate').forEach((element) => {
    element.classList.remove('aos-init');
    element.classList.remove('aos-animate');
  });

  // Divide animation delay by 2 for staging for demonstration purposes.
  if (window.location.href.includes("pdq-staging.effetmonstre.com")) {
    document.querySelectorAll('[data-aos-delay]').forEach((element) => {
      element.dataset.aosDelay = (parseInt(element.dataset.aosDelay) / 2).toString();
    });
  }

  AOS.init();
}, false);

document.addEventListener("swiper:resize", () => {
  AOS.refresh();
});
