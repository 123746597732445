import Axios from 'axios';
import createFileList from "create-file-list/dist/create-file-list.mjs";
import { parseHTMLDocument, parseHTMLElement } from "../utils/html";

//fix pour bug aos avec le delay
// let scrollRef = 0;

// window.addEventListener('scroll', function() {
//   // increase value up to 10, then refresh AOS
//   scrollRef <= 10 ? scrollRef++ : AOS.refresh();
// });

document.addEventListener("page:load", (event) => {
  loadFormSubmitProgressModal();
});

const loadFormSubmitProgressModal = () => {
  let axiosController = null;
  const formSubmitModal = document.getElementById("form-submit-progress-modal");

  if (!formSubmitModal) return;

  formSubmitModal.querySelector('#form-submit-cancel-btn').addEventListener('click', (e) => {
    e.preventDefault();

    axiosController.abort();
  });

  document.querySelectorAll('form.progressable').forEach((form) => {
    form.addEventListener('submit', (e) => {
      e.preventDefault();

      const modal = new Bootstrap.Modal(formSubmitModal, {});
      const formData = new FormData(e.target);
      const progressBar = formSubmitModal.querySelector('.progress-bar');

      axiosController = new AbortController();

      formSubmitModal.querySelectorAll('.progress').forEach((progress) => {
        if (progress.classList.contains('js-progress-final')) {
          progress.classList.add('d-none');
        } else {
          progress.classList.remove('d-none');
        }
      });

      const onUploadProgress = (e) => {
        const percentage = Math.round(e.loaded / e.total * 100);

        if (percentage === 100) {
          formSubmitModal.querySelectorAll('.progress').forEach((progress) => {
            progress.classList.toggle('d-none');
          });
        }

        progressBar.style.width = `${percentage < 15 ? 15 : percentage}%`;
        formSubmitModal.querySelector('.percentage').textContent = `${percentage}%`;
      }

      modal.show();

      Axios.post(e.target.action, formData, {onUploadProgress: onUploadProgress, signal: axiosController.signal})
        .then((response) => {
          if (response.status === 200) {
            Turbo.visit(response.request.responseURL, {action: "replace"});
          } else if (response.status === 301 || response.status === 302) {
            // It seems the browser is hiding the 302 to Javascript or at least that we can't catch it even
            // with follow redirect disabled. Leaving here in case it ever changes...
            Turbo.visit(response.headers.location, {action: "replace"});
          }
        })
        .catch(({response}) => {
          modal.hide();

          setTimeout(() => {
            if (response.status === 422) {
              const { body } = parseHTMLDocument(response.data);
              document.body.replaceWith(body);

              // Turbo.visit(location.href, {
              //   response: {
              //     statusCode: response.status, redirected: false, responseHTML: response.data
              //   }
              // });
            }

          }, 1000);
        });
    });
  });
}
