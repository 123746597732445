document.addEventListener("page:load", () => {
  document.querySelectorAll(".js-toggle-password").forEach((container) => {
    const password = container.querySelector('input');
    const eyes = container.querySelectorAll('svg');

    eyes.forEach((togglePassword) => {
      togglePassword.addEventListener("click", function () {
        const type = password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        eyes.forEach((eye) => {
          eye.classList.toggle('d-none');
        });
      });
    });
  });
});