document.addEventListener("page:load", () => {
    let el = document.querySelector('.hamburger');
    let nav = document.querySelector('.navbar');
    let ul = document.querySelector('.main-menu');
    let buttons = document.querySelectorAll('.navbar .btn-outline-light');

    el.onclick = function () {
        el.classList.toggle('opened');
        document.body.classList.toggle('overflow-hidden');
        nav.classList.toggle('opened');
        ul.classList.toggle('opened');

        buttons.forEach((el)=> {
            let buttonClasses = el.classList;
            if (buttonClasses.contains("btn-outline-light")) {
                setTimeout(() => {
                    buttonClasses.remove('btn-outline-light', 'border-white', 'text-white');
                    buttonClasses.add('btn-outline-secondary');
                }, 200);
            } else {
                buttonClasses.add('btn-outline-light', 'border-white', 'text-white');
                buttonClasses.remove('btn-outline-secondary');
            }
        });

    }
}); 