import Swiper from 'swiper/bundle';

document.addEventListener('page:load', () => {
    function isInViewport(el) {
        const offset = 200; // Minimum visible height
        const rect = el.getBoundingClientRect();
        
        const afterElementStart = rect.y + offset <= (window.innerHeight || document.documentElement.clientHeight);
        const afterElementEnd = rect.y < 0 && Math.abs(rect.y) > rect.height - offset;

        return afterElementStart && !afterElementEnd;
    }

    const swiperResize = (swiper) => {
        const event = new Event('swiper:resize');
        document.dispatchEvent(event);
    }


    document.querySelectorAll('.swiper-container').forEach((el) => {
        new Swiper(el, {
            centeredSlides: true,
            loop: true,
            speed: 500,
            slidesPerView: 3,
            spaceBetween: 60,
            pagination: {
                el: el.querySelector('.swiper-pagination'),
                clickable: true,
            },
            navigation: {
                nextEl: el.querySelector('.swiper-button-next'),
                prevEl: el.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
            },
            on: {
                resize: function(swiper) {
                    swiperResize(swiper);
                }
            }
        });
    });

    document.querySelectorAll('.swiper-process').forEach((el) => {
        const swiper = new Swiper(el, {
            centeredSlides: false,
            loop: true,
            speed: 500,
            effect: "fade",
            slidesPerView: 1,
            spaceBetween: 0,
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 13000,
                running: false,
                disableOnInteraction: false,
            },
            pagination: {
                el: el.querySelector('.swiper-pagination'),
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            on: {
                resize: function(swiper) {
                    swiperResize(swiper);
                }
            }
        });

      document.addEventListener('scroll', function () {
            if (isInViewport(el)) {
                console.log('start');
                swiper.autoplay.start();
                el.classList.add('playing');
            } else {
                el.classList.remove('playing');
                console.log('stop');
                swiper.autoplay.stop();
            }
        });
    }, { passive: true });


    document.querySelectorAll('.swiper-why').forEach((el) => {
        new Swiper(el, {
            centeredSlides: true,
            loop: true,
            speed: 500,
            slidesPerView: 3,
            spaceBetween: 0,
            pagination: {
                el: el.querySelector('.swiper-pagination'),
                clickable: true,
            },
            navigation: {
                nextEl: el.querySelector('.swiper-button-next'),
                prevEl: el.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
                577: {
                    slidesPerView: 1,
                    centeredSlides: false,
    
                },
                992: {
                    slidesPerView: 1.5,
                    centeredSlides: false,
    
                },
                1025: {
                    slidesPerView: 3,
                    centeredSlides: true,
    
                },
                1440: {
                    slidesPerView: 3,
                    centeredSlides: true,
                },
            },
            on: {
                resize: function(swiper) {
                    swiperResize(swiper);
                }
            }
        });
    });

    document.querySelectorAll('.swiper-container-business').forEach((el) => {
        let slidesPerView = parseInt(el.dataset.slideCount) || 4;

        new Swiper(el, {
            centeredSlides: false,
            loop: el.dataset.loop === "true",
            speed: 500,
            slidesPerView: slidesPerView,
            spaceBetween: 45,
            pagination: {
                el: el.querySelector('.swiper-pagination'),
                clickable: true,
            },
            navigation: {
                nextEl: el.querySelector('.swiper-button-next'),
                prevEl: el.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                    centeredSlides: false,
                },
                577: {
                    slidesPerView: 2,
                    centeredSlides: false,

                },
                992: {
                    slidesPerView: slidesPerView,
                    centeredSlides: false,

                },
            },
            on: {
                resize: function(swiper) {
                    swiperResize(swiper);
                }
            }
        });
    });

    document.querySelectorAll('.swiper-retailers-carousel').forEach((el) => {

        new Swiper(el, {
            centeredSlides: false,
            loop: el.dataset.loop === "true",
            speed: 500,
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 45,
            navigation: {
                nextEl: el.querySelector('.swiper-button-next'),
                prevEl: el.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                280: {
                    slidesPerView: 1,
                    centeredSlides: false,
                    slidesPerGroup: 1
                },
                577: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    slidesPerGroup: 2
                },
                992: {
                    slidesPerView: 3,
                    centeredSlides: false,
                    slidesPerGroup: 3
                },
            }
        })
    })

});