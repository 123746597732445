const cookieBanner = () => {
  const OPTION_MANDATORY = 0x0001;
  const OPTION_PREFERENCES = 0x0010;
  const OPTION_STATS = 0x0100;
  const OPTION_ADS = 0x1000;

  const cookieBanner = document.getElementById('cookie-banner');
  const cookieBannerManage = document.getElementById('cookie-banner-manage');

  const cookieManageBtn = document.getElementById('cookie-manage');
  const cookieAcceptAllBtn = document.getElementById('cookie-grant-all');
  const cookieDenyAllBtn = document.getElementById('cookie-deny-all');
  const cookieManageOkBtn = document.getElementById('cookie-manage-ok');
  const cookieManageCloseBtn = document.getElementById('cookie-manage-close');

  const cookieMandatorySwitch = document.getElementById('cookie-switch-mandatory')
  const cookiePreferencesSwitch = document.getElementById('cookie-switch-preferences')
  const cookieStatsSwitch = document.getElementById('cookie-switch-stats')
  const cookieAdsSwitch = document.getElementById('cookie-switch-ads')

  if (cookieBanner && cookieBannerManage && cookieAcceptAllBtn && cookieDenyAllBtn && cookieManageOkBtn && cookieManageCloseBtn
    && cookieManageBtn && cookieMandatorySwitch && cookiePreferencesSwitch && cookieStatsSwitch && cookieAdsSwitch) {

    let options = parseInt(localStorage.getItem('cookies_consent')) || OPTION_MANDATORY;

    const refreshSwitches = () => {
      cookieMandatorySwitch.checked = options & OPTION_MANDATORY;
      cookiePreferencesSwitch.checked = options & OPTION_PREFERENCES;
      cookieStatsSwitch.checked = options & OPTION_STATS;
      cookieAdsSwitch.checked = options & OPTION_ADS;
    }

    const saveAndClose = () => {
      localStorage.setItem('cookies_consent', options);
      cookieBanner.classList.add('d-none');
      cookieBannerManage.classList.add('d-none');
    }

    if (!localStorage.getItem('cookies_consent')) {
      cookieBanner.classList.remove('d-none');
    }

    cookieAcceptAllBtn.onclick = function () {
      options = OPTION_MANDATORY | OPTION_PREFERENCES | OPTION_STATS | OPTION_ADS;
      refreshSwitches();
      saveAndClose();
    }

    cookieDenyAllBtn.onclick = function () {
      options = OPTION_MANDATORY;
      refreshSwitches();
      saveAndClose();
    }

    cookieManageBtn.onclick = function () {
      cookieBannerManage.classList.remove("d-none");
    }

    cookieManageOkBtn.onclick = function () {
      saveAndClose();
    }

    cookieManageCloseBtn.onclick = function () {
      cookieBannerManage.classList.add("d-none");
    }

    cookieMandatorySwitch.onchange = function (e) {
      // Cannot be disabled
      this.checked = true;
      options |= OPTION_MANDATORY;
      e.preventDefault();
    }

    cookiePreferencesSwitch.onchange = function () {
      if (this.checked) {
        options |= OPTION_PREFERENCES;
      } else {
        options &= ~OPTION_PREFERENCES;
      }
    }

    cookieStatsSwitch.onchange = function () {
      if (this.checked) {
        options |= OPTION_STATS;
      } else {
        options &= ~OPTION_STATS;
      }
    }

    cookieAdsSwitch.onchange = function () {
      if (this.checked) {
        options |= OPTION_ADS;
      } else {
        options &= ~OPTION_ADS;
      }
    }

    cookieBannerManage.onclick = function (e) {
      e.stopImmediatePropagation();
    }

    refreshSwitches();

    return {
      mandatoryCookie: () => (options & OPTION_MANDATORY) > 0,
      preferencesCookie: () => (options & OPTION_PREFERENCES) > 0,
      analyticsCookie: () => (options & OPTION_STATS) > 0,
      targetingCookie: () => (options & OPTION_ADS) > 0
    }
  } else {
    console.error("Something went wrong while loading cookie banner.");
  }
}

document.addEventListener("page:load", (event) => window.cookieConsent = cookieBanner());
