import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo;

import * as bootstrap from "bootstrap"
window.Bootstrap = bootstrap; // Necessary for popper.js

import "../controllers";

import "../libs/cocoon";
import "../libs/sweetalert";
import "../libs/plyr";

import "../website/javascript";


