document.addEventListener("page:load", () => {
  document.querySelectorAll('.js-form-submitter').forEach((submitter) => {
    submitter.addEventListener('click', (e) => {
      const form = e.target.closest('form');

      const input = document.createElement('input')
      input.type = "hidden";
      input.classList.add("d-none");
      input.name = "submitted_by"
      input.value = e.target.name;

      form.append(input);
    });
  });
});